import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import $ from 'jquery'
import { GET_SOCIALSETTINGS } from '../../actions';
var Parser = require('html-react-parser');


class Footer extends Component {
	
	 constructor(props) {
		super(props);
		this.state = { footerSocialcontent :"" };
	 }
	 
	componentDidMount() {
		this.props.getSocialsetting();

		/*SCROLL TOP*/
        $(window).scroll(function(){
            if ($(this).scrollTop() > 100) {
                $('.scrollup').fadeIn();
            } else {
                $('.scrollup').fadeOut();
            }
        });
        $('#scrollbutton').click(function(){
            $("html, body").animate({ scrollTop: 0 }, 600);
            return false;
        });

		var btn = $("#scrollbutton");

    $(window).scroll(function () {
      if ($(window).scrollTop() > 600) {
        btn.addClass("show");
      } else {
        btn.removeClass("show");
      }
    });

    btn.on("click", function (e) {
      e.preventDefault();
      $("html, body").animate({ scrollTop: 0 }, "600");
    });
	}
	
	componentDidUpdate(prevProps) {
		
		if (this.props.socialsettingData != prevProps.socialsettingData) {

			this.setState( { footerSocialcontent: this.props.socialsettingData[0].result_set });
		}
		
	  }

	render() {

		var todayTimeSp = new Date();
		var yearSp = todayTimeSp.getFullYear();

		let socialsettingData = [];

		let facebook,instagram,email_info,youtube,twitter,linkedin,pinterest ='';


		if(this.state.footerSocialcontent){
			socialsettingData = this.state.footerSocialcontent;
	  
			if(socialsettingData.socailnetwork_facebook_url){
			  facebook = <a href={socialsettingData.socailnetwork_facebook_url}  target="_blank" className="facebook icon"></a>
			}
			
	  
			if(socialsettingData.socailnetwork_instagram_url){
			  instagram = <a href={socialsettingData.socailnetwork_instagram_url} target="_blank" className="instagram icon"></a>
			}
	  
			if(socialsettingData.socailnetwork_gplus_url){
			  email_info = <a href={"mailto:"+socialsettingData.socailnetwork_gplus_url} target="_top" rel="noopener noreferrer" className="mail icon"></a>
			}
			
			youtube = <a href="" target="_blank"  className="youtube icon"></a>
	  
			
		  }

		return (	
			<>
			<div className="scrolltop" id="scrollbutton">
				<a href="javascript:void(0)">
					<span>
						<i className="fa fa-angle-up ars"></i>
					</span>
				</a>
			</div>
			 <footer>
				 <div className="footer footer-bg">
				  	<div className="footer-inner">
						{/*<div className="footer-menu">
							<ul>
								<li>
									<a href="/terms-conditions">Terms & Condition</a>
								</li>
								<li>
									<a href="/privacy-policy">Privacy Policy</a>
								</li>
							</ul>
						</div>*/}
						<p className="foot_copy">Copyright {yearSp} <span>BBBurgers</span> All rights reserved. Design By Jankosoft</p>
						<div class="social-media-footer"><span class="follow-us">Follow Us</span>
							<div className="social-footer">
							{facebook}
							{instagram} 
							{email_info} 
							</div>
						</div>			
					</div> 
			 	</div> 
			 </footer>
			</>
		);
	}
}

const mapStateTopProps = (state) => {
	return {
		socialsettingData: state.socialsettings,
	}
  }

  const mapDispatchToProps = (dispatch) => {
	return {
		getSocialsetting: () => {
		dispatch({ type: GET_SOCIALSETTINGS });
	  }
	}
  }

export default connect(mapStateTopProps, mapDispatchToProps)(withRouter(Footer));
