/* eslint-disable */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link} from 'react-router-dom';
import axios from 'axios';
import Moment from 'moment';
import cookie from 'react-cookies';

import { stripslashes, addressFormat, getCalculatedAmount, callImage, showLoader, hideLoader, showAlert } from "../Helpers/SettingHelper";
import { appId, deliveryId, CountryTxt,apiUrlNotify } from "../Helpers/Config";

import { GET_ORDER_DETAIL } from '../../actions';

import Header from '../Layout/Header';
import Footer from '../Layout/Footer';


import tick from "../../common/images/tick.png";
import productImg from "../../common/images/noimg-400x400.jpg";

var Parser = require('html-react-parser');

class Thankyou extends Component {
  constructor(props) {
    super(props);
    
    }
    
    componentWillMount() {
        let orderId = (typeof this.props.match.params.orderId !== "undefined" ? this.props.match.params.orderId : "");
    var chkOrderid = (typeof cookie.load('ChkOrderid') === 'undefined') ? '' : cookie.load('ChkOrderid');
    if(orderId !== '' && chkOrderid === orderId) {  
      this.props.getOrderDetail(orderId);
      this.sendNotification();
    } else {
      showAlert('Error', 'Invalid order detail.');
      $.magnificPopup.open({
        items: {
          src: '.alert_popup'
          },
          type: 'inline'
      });
      this.props.history.push('/');
    }
    }

    componentDidMount() {
    /*console.log('DidMount');*/
    }

    sendNotification() {

    axios.get(apiUrlNotify+'order_notify').then(res => { }); 
  }
  

    loadItems(orderDetail) {
    if (Object.keys(orderDetail).length > 0) {

      var remarks = orderDetail.order_remarks !== ''?<div className="remark_notesec text-left"><h4>Remarks</h4><p>{orderDetail.order_remarks}</p></div>:'';
      var cartItems = orderDetail['items'];
      return cartItems.map((item, index) =>

          <div className="cart_row" key={index}>
            <div className="row">
            <div class="col-xs-8 cart_left">
                <div className="cart_img">
                  <a href="javascript:void(0)" title="">
                    <img src={(item.item_image !== '')?item.item_image:productImg} alt="" />
                  </a>
                </div>
                <div className="cart_info">
                  <h4>{stripslashes(item.item_name)} X {item.item_qty}</h4>

                  {this.loadModifierItems("Component", item.modifiers, item.set_menu_component)}
                  {item.item_specification !== '' && <p className="help-block">{stripslashes(item.item_specification)}</p>}
                </div>
                
              </div>
              <div class="col-xs-4 cart_right text-right">
                <div className="cart_price"><p>S${parseFloat(item.item_total_amount).toFixed(2)}</p></div>
              </div>
            </div>
          </div>

      );
    } else { }
  }
  
  /* this function used to load modifer items */
  loadModifierItems(itemType, modifiers, combo) {
    var len = modifiers.length;
    var comboLen = combo.length;
    var html = '<div class="cart_extrainfo">';

    if (len > 0) {
      for (var i = 0, length = len; i < length; i++) {
        var modName = modifiers[i]['order_modifier_name'];
        var modval = modifiers[i]['modifiers_values'][0]['order_modifier_name'];
        html += "<p><b>" + stripslashes(
          modName) + ":</b></p><p> " + stripslashes(
            modval) + "</p> ";
      }
      html += "</div>";
      var reactElement = Parser(html);
      return reactElement;

    } else if (comboLen > 0) {
      for (var i = 0, length = comboLen; i < length; i++) {
        var comboName = combo[i]['menu_component_name'];
        var comboVal = this.showComboProducts(combo[i]['product_details']);

        html += "<p><b>" + comboName + ":</b></p><p> " + comboVal + " " + this.showComboModifiers(combo[i]['product_details'][0]['modifiers']) + "</p> ";
      }
      html += "</div>";
      var reactElement = Parser(html);
      return reactElement;

    }
  }
  
  /* show combo products  list */
  showComboProducts(combos) {
    var lenCombo = combos.length;
    var html = " ";
    if (lenCombo > 0) {
      for (var r = 0, lengthCombo = lenCombo; r < lengthCombo; r++) {
        var comboPro = combos[r]['menu_product_name'];
        var comboQty = combos[r]['menu_product_qty'];
        var comboPrice = combos[r]['menu_product_price'];
        var newPrice = (comboPrice > 0) ? " (+ S$" + comboPrice + ")" : "";
        html += "<p>" + comboQty + " X " + comboPro + newPrice + " </p> ";
      }
      return html;
    }
    return "";
  }

  /* this function used to show combo modifieirs list */
  showComboModifiers(modifiers) {

    var lenMod = modifiers.length;
    var html = '<div >';
    if (lenMod > 0) {
      for (var i = 0, length = lenMod; i < length; i++) {
        var modName = modifiers[i]['order_modifier_name'];
        var modval = modifiers[i]['modifiers_values'][0]['order_modifier_name'];
        var modValPrice = modifiers[i]['modifiers_values'][0]['order_modifier_price'];
        var newModValPrice = (modValPrice > 0) ? " (+ S$" + modValPrice + ")" : "";
        html += "<p><b>" + modName + ":</b></p><p> " + modval + newModValPrice + "</p> ";
      }
      html += '</div>';

      return html;

    }

    return "";
  }

    render() {

    let orderArr = this.props.orderdetail;
    var orderDetail = [];

    if(Object.keys(orderArr).length > 0) {
      if(orderArr[0].status === 'ok') {
        orderDetail = orderArr[0].result_set[0];
      }else {
        this.props.history.push('/');
      }
    }

    return (
        <div className="home-wrapper inner-page-wrapper"> 

          {/*-------Header---------*/}  
          <Header />          

          {/*------wraper-------------*/}             
                  
          
          <div className="wraper tnk-you-wrapper">
         
        {(Object.keys(orderDetail).length > 0) &&           
          <div class="tnk-you">
            <div class="container">


              <div class="tnk-main-wrapper">      
                <div class="tick">
                  <img src={tick} alt="tick"/>
                  <h2>Thank You </h2>
                  <span>Your order has been placed successfully</span>
                </div>
                <div className="tnk-inner-wrapper">     
                  <div className="cart-header">
                    <div className="ctitle">
                    <h3>Order No - {orderDetail.order_local_no}</h3>              
                    </div>
                  </div>
                  <div class="tnk-order">
                  <p>Order placed at :  {Moment(orderDetail.order_created_on).format('DD-MM-YYYY hh:mm A')}<br></br>Pay by : {orderDetail.order_method_name}</p>
                  </div>
                  
                  <div class="tnk-delivery">
                  {orderDetail.order_availability_id === deliveryId ? (
                    <>
                    <div class="delivery_total">
                      <div class="delivery_total_left"> 
                      <h4>Order Handled By</h4>
                      <p>{stripslashes(orderDetail.outlet_name)}</p>
                      <p>{addressFormat(orderDetail.outlet_unit_number1, orderDetail.outlet_unit_number2, orderDetail.outlet_address_line1, orderDetail.outlet_address_line2, orderDetail.outlet_postal_code)}</p>
                      </div>
                      <div class="delivery_total_left delivery_total_right"> 
                      <h4>Delivery Location</h4>
                      <p>{addressFormat(orderDetail.order_customer_unit_no1, orderDetail.order_customer_unit_no2, orderDetail.order_customer_address_line1, orderDetail.order_customer_address_line2, orderDetail.order_customer_postal_code)}</p>  
                      </div>
                    </div>
                    <div class="delivery_total delivery_total_number">
                      <div class="delivery_total_left">
                        <h5>Delivery Date</h5>
                        <h3>{Moment(orderDetail.order_date).format('DD-MM-YYYY')}</h3>
                      </div>
                      <div class="delivery_total_left delivery_total_right">
                        <h5>Delivery Time</h5>
                        <h3>{Moment(orderDetail.order_date).format('hh:mm A')}</h3>
                      </div>
                    </div>
                    </>
                  ) : (
                    <div className="pickup-cart-div picup_body">
                      <div className="cart-direction"></div>
                      <div className="cart_row cart-header-first">
                        <div className="pickup-thankfull text-center">
                          <h4>Pickup Location</h4>
                          <p>{stripslashes(orderDetail.outlet_name)}</p>
                          <p>
                            {addressFormat(
                              orderDetail.outlet_unit_number1,
                              orderDetail.outlet_unit_number2,
                              orderDetail.outlet_address_line1,
                              orderDetail.outlet_address_line2,
                              orderDetail.outlet_postal_code
                            )}
                          </p>
                        </div>
                      </div>
                    </div>
                  )}
                    
                  <br />

                  <div className="delivery_total delivery_total_number delivery_datetime_div">
                    <div className="delivery_total_left">
                      <h2>
                        {orderDetail.order_availability_id === deliveryId
                          ? "Delivery"
                          : "Pickup"}{" "}
                        Date
                      </h2>
                      <h4 className="checkoutDate">
                        {Moment(orderDetail.order_date).format("DD-MM-YYYY")}
                      </h4>
                    </div>
                    <div className="delivery_total_left delivery_total_right">
                      <h2>
                        {orderDetail.order_availability_id === deliveryId
                          ? "Delivery"
                          : "Pickup"}{" "}
                        time
                      </h2>
                      <h4 className="checkoutTime">
                        {orderDetail.order_pickup_time_slot_from !== "" &&
                        orderDetail.order_pickup_time_slot_from !== "00:00:00"
                          ? Moment(
                              orderDateonly +
                                " " +
                                orderDetail.order_pickup_time_slot_from
                            ).format("hh:mm A") +
                            " - " +
                            Moment(
                              orderDateonly +
                                " " +
                                orderDetail.order_pickup_time_slot_to
                            ).format("hh:mm A")
                          : Moment(orderDetail.order_date).format("hh:mm A")}
                      </h4>
                    </div>
                  </div>


                    <div class="hcart_tt">
                      <div class="row-replace">
                        <div class="col-sm-cls text-left">
                          <h3>Your Items</h3>
                        </div>
                      </div>
                    </div>


                    
                                      
                    <div class="cart_body pdt-cart-list">

                    {this.loadItems(orderDetail)}
                          
                    </div>          
                      
                      


                  </div>
                  
                  <div class="cart_footer">
                          
                          
                      
                        <div className="cart_row">
                          <div className="row">
                            <div className="col-xs-8">
                              <p className="text-uppercase">SUBTOTAL</p>
                            </div>
                            <div className="col-xs-4 text-right">
                              <span>S${orderDetail.order_sub_total}</span>
                            </div>
                          </div>
                        </div>

                        {parseFloat(orderDetail.order_delivery_charge) > 0 && <div className="cart_row">

                        <div className="row">
                          <div className="col-xs-8">
                            <p className="text-uppercase">Delivery Charge</p>
                          </div>
                          <div className="col-xs-4 text-right">
                            <span>S${parseFloat(orderDetail.order_delivery_charge).toFixed(2)}</span>
                          </div>
                        </div>

                        </div>}

                        {parseFloat(orderDetail.order_additional_delivery) > 0 && <div className="cart_row">

                        <div className="row">
                          <div className="col-xs-8">
                            <p className="text-uppercase">Additional Delivery Charge</p>
                          </div>
                          <div className="col-xs-4 text-right">
                            <span>S${parseFloat(orderDetail.order_additional_delivery).toFixed(2)}</span>
                          </div>
                        </div>

                        </div>}

                        {parseFloat(orderDetail.order_discount_amount) > 0 && <div className="cart_row">
                          <div className="row">
                            <div className="col-xs-8">
                              <p className="text-uppercase">Discount (-)</p>
                            </div>
                            <div className="col-xs-4 text-right">
                              <span>S${parseFloat(orderDetail.order_discount_amount).toFixed(2)}</span>
                            </div>
                          </div>
                        </div>}

                        {parseFloat(orderDetail.order_tax_calculate_amount) > 0 && <div className="cart_row">

                        <div className="row">
                          <div className="col-xs-8">
                            <p className="text-uppercase">GST ({parseFloat(orderDetail.order_tax_charge)}%)</p>
                          </div>
                          <div className="col-xs-4 text-right">
                            <span>S${parseFloat(orderDetail.order_tax_calculate_amount).toFixed(2)}</span>
                          </div>
                        </div>

                        </div>}
                        


                          <div class="cart_row cart_footer_totrow">
                            <div class="row">
                              <div class="col-xs-8">
                                <p class="text-uppercase">Total</p>
                              </div>
                              <div class="col-xs-4 text-right">
                                <span>S${parseFloat(orderDetail.order_total_amount).toFixed(2)}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                  <div class="tnk-chk-order">
                  <Link to={"/myorders"} className="btn  btn_minwid">Check My Order</Link>
                  </div>
                </div>
              </div>
              


            </div>    
          </div>
          }

          </div>
          {/*------ wraper end--------*/} 
          
  
          {/*------Footer--------*/}
          <Footer />


          </div>
         )
    } 
}


const mapStateTopProps = (state) => {
  return {
    orderdetail: state.orderdetail
  }
  }
  
  const mapDispatchToProps = (dispatch) => {
  return {
    getOrderDetail: (orderId) => {
    dispatch({ type: GET_ORDER_DETAIL, orderId });
    },
  }
  }
  
  export default connect(mapStateTopProps, mapDispatchToProps)(Thankyou);
