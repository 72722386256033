import React, { Component } from 'react';
import { Link,withRouter } from 'react-router-dom';
import cookie from 'react-cookies';
import { connect } from 'react-redux';
import axios from 'axios';
import { setMinutes, setHours, getDay, format } from 'date-fns';

import Footer from '../Layout/Footer';

import OrderdatetimeSlot from "../Layout/OrderdatetimeSlot.js";

import logo from "../../common/images/logo.png";
import homeBg from "../../common/images/home-bg.jpg";

import burgerImg from "../../common/images/burger-img.png";
import cartImg from "../../common/images/cart.png";
import OrdernowBtn from "../../common/images/ordernow.png";
import { appId, apiUrl, apiUrlV2, deliveryId } from "../Helpers/Config";

import { getReferenceID, showAlert, showLoader, hideLoader, getAliasName, stripslashes, removeOrderDateTime, removePromoCkValue, addressFormat } from "../Helpers/SettingHelper";

import deliveryImg from "../../common/images/ico_delivery.png";
import iconWin from "../../common/images/ico_awesome.png";
import warningImg from "../../common/images/warning.svg";
import iconUnhappy from "../../common/images/ico_sad.png";
import ico_login from "../../common/images/ico_login.png";
import searchImg from "../../common/images/search-interface-symbol.png";
import brushStroke from "../../common/images/mob-brush-stroke.png";
import mobLogo from "../../common/images/mob-logo.png";
import userLogin from "../../common/images/user2.png";
import $ from 'jquery';


import { GET_MENUDATA, GET_ALLUSERSECADDRDATA, GET_ZONE_DETAIL} from '../../actions';


class Home extends Component {
	constructor(props) {
		super(props);
		this.state = {
			menu: false,
			menuData:[],
			footerSocialcontent:'',
			secondaryaddresslist: [],
			seletedAvilablityId:'',seletedOutletId:'',order_tat_time:0,globalsettings: [], secondaryaddresslist: [], deliveryOutlets: [], deliveryOutletsList: [], delivery_outlet_id:'', orderHandled: '', orderDeliveryAddress: '', nextavail: '',  getDateTimeFlg:'', deliveryInfo: [],seleted_ord_date: '',seleted_ord_time: '',
		};

	    var availbty = cookie.load('defaultAvilablityId');
		var outltIdTxt = (typeof cookie.load('orderOutletId') === 'undefined') ? '' : cookie.load('orderOutletId');
		var zoneIdTxt = (typeof cookie.load('orderZoneId') === 'undefined') ? '' : cookie.load('orderZoneId');

		if(availbty === deliveryId && outltIdTxt !== '' && zoneIdTxt !== '') {
			this.state['delivery_outlet_id'] = outltIdTxt;
			this.props.getZoneDetail(outltIdTxt, zoneIdTxt);
		}
		
	  }
 
	componentDidMount() {

     this.props.getMenuData('main');
     this.props.getSecondaryAddress();

     if ($(".open-popup-link").length > 0) {
        window.$('.open-popup-link').magnificPopup({
          type: 'inline',
          midClick: true 
        });
      }

     /* Input lable animation */
     if ($(".input-focus").length > 0) {
        $('.input-focus').focus(function() {
            $(this).parents('.focus-out').addClass('focused');
        });
        $('.input-focus').blur(function() {
            var inputValue = $(this).val();
            if (inputValue == "") {
                $(this).removeClass('filled');
                $(this).parents('.focus-out').removeClass('focused');
            } else {
                $(this).addClass('filled');
            }
        });
     }

	}

	componentDidUpdate (prevProps) {

		if (this.props.socialsettingData !== prevProps.socialsettingData) {

			   this.setState( { footerSocialcontent: this.props.socialsettingData[0].result_set });
		   }

   }

	componentWillReceiveProps(PropsDt) {

		if(PropsDt.menudata !== this.props.menudata){
			this.setState({menudata: PropsDt.menudata[0].result_set});
		}

	    if(PropsDt.secondaryaddresslist !== this.state.secondaryaddresslist) {
			this.setState({secondaryaddresslist: PropsDt.secondaryaddresslist});
		}


	}

	handleMenu(){
			this.setState(prevState => ({menu: !prevState.menu})); 

	}

	handleLogin(){
		cookie.save("loginpopupTrigger", 'Fromhome');
		this.props.history.push('/menu');

}

	createLink(menu){
		var url = window.location.pathname;
		if(menu.nav_type==="0")
		{
		  return <Link to={"/page/"+menu.nav_title_slug} title="" className={(url === "/page/"+menu.nav_title_slug)? 'active' : ''}>
			
						  <span>{menu.nav_title}</span>
						</Link>;
	
		  }
		  else if(menu.nav_type==="3")
		  {  
			let target= ""; 
			if(menu.nav_link_type === "self"){
			  target= ""; 
			}else{
			  target = "_blank";
			}
		   return  <a target={target} href={menu.nav_pages} title={menu.nav_title}><span>{menu.nav_title}</span></a>;
		   }
		}
	
		listMainNavigation(){
			 return this.state.menudata.map(function (menu, i) {
				   return  (<li key={i+100}>{this.createLink(menu)}</li>);
		   },this)
	}


	userAddressList(typeTxt) {
		if (this.state.secondaryaddresslist.length > 0) {
			  var addListTxt = this.state.secondaryaddresslist.map((addr, index) =>
				  <div className="address_linfo" key={typeTxt+'-'+index}>
					 <div className="custom_radio">
					  <input type="radio" name={"address_chk"+typeTxt} value={addr.postal_code} className="address_chk" onChange={this.changPostalValue.bind(this, typeTxt, addr.postal_code)} />
					  <span>{(addressFormat(addr.unit_code, addr.unit_code2, addr.address, addr.country, addr.postal_code))}</span>
					  </div>		
				  </div>);
				  
				return (<div>
						 {addListTxt}
						 <div className="address_linfo" key={typeTxt+'dfl'}>
							 <div className="custom_radio">
							  <input type="radio" name={"address_chk"+typeTxt} value='' defaultChecked={true} className="address_chk" onChange={this.changPostalValue.bind(this, typeTxt, '')} />
							  <span>Enter New Address</span>
							  </div>		
						  </div> 	
						</div>);	
				  
		  } else {
			  return ''
		  }
		
	}

	openPopup(){
		window.$.magnificPopup.open({
		items: {
		  src: '#delevery-postcode-popup'
		},
		type: 'inline'
		});
	}


	gobckOutletpopup() {
		window.$.magnificPopup.close();
		this.props.history.push('/');
		return false;
	}	


	showUnitNum(unit1,unit2) {
		unit1 = (typeof unit1 !== 'undefined') ? unit1 : '';
		unit2 = (typeof unit2 !== 'undefined') ? unit2 : '';

		if(unit1 !== '') {
			var unitNo = (unit2 !== '') ? unit1+' - '+unit2 : unit1;
		} else {
			var unitNo = unit2;
		}
	
		return (unitNo !== '')?'#'+unitNo:'';
	}


    /* find Zone*/
	findOutletBasedZone(first, availability) {

		if (first) {
			var postalcode = $("#postalcode").val();
		} else {
			var postalcode = $("#postalcode1").val();
		}
		
		var outletIdTxt = this.state.delivery_outlet_id;

		/*if (outletIdTxt === '') {
			$(".postal_error").html('<span class="error">Go Back and Select your delivery outlet.</span>');
			return false;
		}*/
		
		if (postalcode.length < 5) {
			$(".postal_error").html('<span class="error">Please enter valid postal code.</span>');
			return false;
		}
		
		showLoader('delivery_submit_cls','class'); 

		var header = {
		headers: { 'X-API-KEY': 'D04Ea1c5-b19c-4B58-b2e9-88F5C0456432' }
		}
		
		if(cookie.load('Token')!='' && cookie.load('Token')!=undefined) {
		header.headers.Auth = cookie.load('Token')
		}
	
		axios.all([ 
		axios.get(apiUrlV2+'outlets/findOutletZone?app_id='+appId+"&availability_id="+availability+"&postal_code="+postalcode+"&postalcode_basedoutlet=yes", header),
		axios.get(apiUrlV2+'settings/chkTimeslotIsAvaiable?app_id='+appId+"&availability_id="+availability+"&outletId="+outletIdTxt, header),
			]).then(axios.spread((res,timeslt) => {
					
					hideLoader('delivery_submit_cls','class');
					
					var deliveryInfo = [];

					/* Success response */
					if (res.data.status === "ok") {
						window.$.magnificPopup.close();
						cookie.save('outletchosen', availability);

						var orderDeliveryAddress = res.data.result_set.postal_code_information.zip_buno + " " + res.data.result_set.postal_code_information.zip_sname;
						var orderHandled = stripslashes(res.data.result_set.outlet_name) + ", Crew will be seeing you in " + res.data.result_set.outlet_delivery_timing + " Minutes";
						
						deliveryInfo['orderZoneId'] = res.data.result_set.zone_id;
						deliveryInfo['orderOutletId'] = res.data.result_set.outlet_id;
						deliveryInfo['orderOutletPostalCode'] = res.data.result_set.outlet_postal_code;
						deliveryInfo['orderOutletName'] = stripslashes(res.data.result_set.outlet_name);
						deliveryInfo['orderPostalCode'] = res.data.result_set.postal_code_information.zip_code;
						deliveryInfo['orderTAT'] = res.data.result_set.outlet_delivery_timing;
						deliveryInfo['orderDeliveryAddress'] = orderDeliveryAddress;
						deliveryInfo['orderHandled'] = orderHandled;
						deliveryInfo['defaultAvilablityId'] = availability;

						var unitNum = this.showUnitNum(res.data.result_set.outlet_unit_number1,res.data.result_set.outlet_unit_number2);	

						var orderHandledText = stripslashes(res.data.result_set.outlet_name) + " " + res.data.result_set.outlet_address_line1 + " " + res.data.result_set.outlet_address_line2 + ", "+unitNum+" Singapore " + ((res.data.result_set.outlet_postal_code != undefined && res.data.result_set.outlet_postal_code !== '')?res.data.result_set.outlet_postal_code:postalcode);
						deliveryInfo['orderHandledByText'] = orderHandledText;

						this.setState({deliveryInfo: deliveryInfo,seletedAvilablityId: availability, seletedOutletId: res.data.result_set.outlet_id, order_tat_time: res.data.result_set.outlet_delivery_timing, orderHandled: orderHandled, orderDeliveryAddress: orderDeliveryAddress + " Singapore " + res.data.result_set.postal_code_information.zip_code });

						/* Success time slot response */
						if (timeslt.data.status === "success") {
							this.setState({getDateTimeFlg: 'yes'});

							removeOrderDateTime();
							removePromoCkValue();
								
							window.$.magnificPopup.open({
								items: {
									src: '#awesome-popup'
								},
								type: 'inline'
							});


						} else {
							window.$.magnificPopup.open({
								items: {
									src: '#outlet-error-popup'
								},
								type: 'inline'
							});
						}
						
					}

					/* Error response */
					if (res.data.status === "error") {
					
						this.setState({deliveryInfo: deliveryInfo}); 			

						if(res.data.shop_close !== undefined && res.data.shop_close == 'Yes') {

							showAlert('Error', 'Unable to take your orders right now, please try again later.');
							window.$.magnificPopup.open({
								items: {
								src: '.alert_popup'
								},
								type: 'inline'
							});
							this.props.history.push('/');

						} else {
						
						window.$.magnificPopup.close();
						window.$.magnificPopup.open({
						items: {
							src: '#error-postal-popup'
						},
						type: 'inline'
						});
						
						}
						
						if (first === 0) {
						var mgsTxt = (res.data.message !== '') ? res.data.message : 'Please enter valid postal code.';  
						$(".postal_error").html('<span class="error">'+mgsTxt+'</span>');
						}
						
					}
									
				}));
	}



	setOrderOutletDateTimeData() {
		var seletedOrdDate = this.state.seleted_ord_date;
		var seletedOrdTime = this.state.seleted_ord_time;
		if(seletedOrdDate !== '' && seletedOrdTime !== '' && seletedOrdDate !== null && seletedOrdTime !== null) {
			var orderInfoData =  this.state.deliveryInfo;

			if(Object.keys(orderInfoData).length > 0) {
				
				var OrderDate = format(seletedOrdDate, "yyyy-MM-dd");
				var OrderHours = seletedOrdTime.getHours();
				var OrderMunts = seletedOrdTime.getMinutes();
				var OrderSecnd = seletedOrdTime.getSeconds();
				var orderDateTime = new Date(OrderDate);
					  orderDateTime.setHours(OrderHours);
					  orderDateTime.setMinutes(OrderMunts);
					  orderDateTime.setSeconds(OrderSecnd);
			
                var deliveryDate = format(seletedOrdDate, "dd/MM/yyyy");
				var deliveryTime = this.convPad(OrderHours)+":"+this.convPad(OrderMunts)+":"+this.convPad(OrderSecnd);			
				cookie.save('orderDateTime', orderDateTime);
				cookie.save('deliveryDate', deliveryDate);
				cookie.save('deliveryTime', deliveryTime);
				
				
				if(this.state.seletedAvilablityId === deliveryId) {
					cookie.save('orderZoneId', orderInfoData['orderZoneId']);
					cookie.save('orderDeliveryAddress', orderInfoData['orderDeliveryAddress']);
				}
				
				cookie.save('orderOutletId', orderInfoData['orderOutletId']);
			    cookie.save('orderOutletName', orderInfoData['orderOutletName']);
			    cookie.save('orderPostalCode', orderInfoData['orderPostalCode']);
				cookie.save('orderTAT', orderInfoData['orderTAT']);
				cookie.save('orderHandled', orderInfoData['orderHandled']);
				cookie.save('defaultAvilablityId', orderInfoData['defaultAvilablityId']);
				cookie.save('orderHandledByText', orderInfoData['orderHandledByText']);
				cookie.save('outletchosen', orderInfoData['defaultAvilablityId']);
			    window.$.magnificPopup.close();
			    if(cookie.load('popuptriggerFrom') === 'FeaturedPro') {
				   cookie.remove('popuptriggerFrom');
				   //this.props.history.push('/');
				   this.props.history.push('/menu');
			    } else {
				  this.props.history.push('/menu');
				  // window.location.reload();
			    }
				
			} else {
				window.$.magnificPopup.open({
					items: {
					  src: '#outlet-error-popup'
					},
					type: 'inline'
			    });
			}
			
		} else {
			$(".ordrdatetime_error").html('<span class="error"> Please select order date and time.</span>');
		}
	}

    setdateTimeFlg = (field, value) => {
        if(field == 'tmflg') {
              this.setState({getDateTimeFlg:value});
        }   
        else if(field == 'ordDate') {
              var ordTime = '';
              $('.ordrdatetime_error').html('');
              this.setState({seleted_ord_date:value,seleted_ord_time:ordTime});
        }       
        else if(field == 'ordTime') {
              $('.ordrdatetime_error').html('');
              this.setState({seleted_ord_time:value});
        }
        else if(field == 'triggerErrorPopup') {
              $.magnificPopup.open({
                  items: {
                    src: '#outlet-error-popup'
                  },
                  type: 'inline'
              });
        }     
    }


    changPostalValue(type,pstVl) {
        if(type === 1) {
          $("#postalcode").val(pstVl);
        } else {
          $("#postalcode1").val(pstVl); 
        }
    }



	 convPad(d) {
        return (d < 10) ? '0' + d.toString() : d.toString();
    }


  validateIntval(e) {
    const re = /[0-9]+/g;
    if (!re.test(e.key)) {
      e.preventDefault();
    }
  }

	  render() {

		let url = window.location.pathname;

		let currenturl = window.location.href;

		let isCheckout = "checkout";

		let socialsettingData = [];

		let facebook,instagram,email_info ='';

		if(this.state.footerSocialcontent){

			socialsettingData = this.state.footerSocialcontent;
	
			if(socialsettingData.socailnetwork_facebook_url){
				facebook = <a href={socialsettingData.socailnetwork_facebook_url}  target="_blank" className="facebook icon"></a>
			}

			if(socialsettingData.socailnetwork_instagram_url){
				instagram = <a href={socialsettingData.socailnetwork_instagram_url} target="_blank" className="instagram icon"></a>
			}
		
			if(socialsettingData.socailnetwork_gplus_url){
				email_info = <a href={"mailto:"+socialsettingData.socailnetwork_gplus_url} target="_top" rel="noopener noreferrer" className="mail icon"></a>
			}
		}

		return (
				<div className="home-page-wrapper inner-page-wrapper">	
				
				
						<div className="header">
						  <div className="header-top-cls">
							 <div className="container-full">
							 	<div className="logo-main-section">
								 <div className="logo-left">	
									 <Link to={"/"} title=""><img src={logo} /></Link>
							 	</div>
								 <div className="header-right">
							 
								 <a href="javascript:void(0)" onClick={this.handleMenu.bind(this)} className={(this.state.menu===true)?'trigger-menu menu-icon active':'trigger-menu menu-icon'}>
									  <span className="icon_menu1"></span>
									  <span className="icon_menu2"></span>
									  <span className="icon_menu3"></span>
								</a>
								
							 	</div>
								</div>
							 </div>
						  </div>	

						  
						 <div className={(this.state.menu===true)?'full-screen-overlay active':'full-screen-overlay'}>
						  <div className="overlay-menu-wrap">
							 <div className="logo-header">
								<Link to={"/"} className="logo"><img src={logo} /></Link>
							 </div>
							 <div className="overlay-menu">	

								  <ul>
								      
									  {!cookie.load("UserId") && 
										 <li> <a href="javascript:void(0)" onClick={this.handleLogin.bind(this)} title="Login">Login	</a></li>

									  }

									  {cookie.load("UserId") &&  
										 <li><Link to={"/myaccount"} title="My Account">My Account</Link></li>
									  }
									  <li><Link to={"/menu"} title="Menu">Menu</Link></li>
									  <li><Link to={"/contact-us"} title="Contact Us">Contact Us</Link></li>	
									  <li><a onClick={this.openPopup.bind(this)}title="Order Now">Order Now</a></li>
								  </ul>

							 </div>
							 <div className="social-media-overlay">								
								<span className="follow-us">Follow Us</span>
								{facebook}
								{instagram} 
								{email_info}
							 </div>
						  </div>
					 </div>

					 <div className="header_back"></div>

			  </div>
				
				
				<div className="home_wrap">						
					<div className="home_main_img">
							<Link to={"/menu"} title="">
								<span className="order_now_img"></span>
							</Link>
							<Link to={"/menu"} className="ordernow-link" title="">
								<span className="order_now_button">
								<img src={OrdernowBtn} />
								</span>
							</Link>
					</div>
				  </div>
				
			

						 {/*  Delivery Postal code Popup - start */}
			 <div id="delevery-postcode-popup" className="white-popup mfp-hide popup_sec delevery_popup">
					<div className="popup_equalrw">
					  <div className="popup_ttsec">
						<div className="innervmid_in">
						  <div className="pop_title">
						    {/*<img src={scooterBg} />*/}
							<img className="pop-scooter-img" src={deliveryImg} />
							<h2 className="text-uppercase">Let us know</h2>
							<small>Your Delivery Location</small>
						  </div>
						</div>
					  </div>
					  <div className="popup_right">
						<div className="innervmid_in">
							
						    {cookie.load("UserId") &&  <div className="address-list-cls address-list-main">
							{this.userAddressList(1)}
						    </div>}
							
							<div className="form-group">
							  <div className={(this.state.secondaryaddresslist.length > 0)?"focus-out focused":"focus-out"}>
								<label>Enter your postal code</label>
								<input type="text" id="postalcode" onKeyPress={(e) => this.validateIntval(e)} maxLength="6"  className="form-control input-focus" />
								<div className="postal_error"></div>
							  </div>
							</div>
							<div className="btn_sec">
							  <div className="two-button-row">
								<div className="go_second">
								
								  <a href="javascript:;" onClick={this.gobckOutletpopup.bind(this)} className="button button-left" title="Go Back">Go Back</a>
								</div>
								<div className="con_first delivery_submit_cls">
								
								<input type="button" onClick={this.findOutletBasedZone.bind(this, 1, deliveryId)} className="button button-right delivery_submit" value="Continue" />
								</div>
							  </div>
							</div>
						</div>
					  </div>
					</div>
				  </div>
			 {/* Delivery Popup - end */}

		 {/* success popup - Start */}
			<div id="awesome-popup" className="white-popup mfp-hide popup_sec delevery_popup delevery_popup_datetime">
			<div className="popup_equalrw">
				<div className="popup_ttsec">
				<div className="innervmid_in">
					<div className="pop_title">
					<img className="pop-scooter-img" src={iconWin} />
					<h2 className="text-uppercase">Awesome</h2>
					{(cookie.load('defaultAvilablityId') === deliveryId) && <small>We can Deliver !</small>}
					</div>
					<div className="awesome_del">
					<h5>Your Delivery Address:</h5>
					<h2>{this.state.orderDeliveryAddress}</h2>
					</div> 
				</div>
				</div>
				<div className="popup_right">
				<div className="innervmid_in">
					
					<div className="datetime_selt_sec">
						<div className="datetime_selt_lbl">
						{(this.state.seletedAvilablityId === deliveryId) ? 'Select Your Delivery Date & Time' : 'Select Your Pickup Date & Time'}
						</div> 
						
						{!currenturl.includes(isCheckout) && <OrderdatetimeSlot {...this.props} hdrState={this.state} setdateTimeFlg={this.setdateTimeFlg} />}
						
						<div className="ordrdatetime_error"></div>
					</div>
					
					<div className="btn_sec">
						<input type="button" onClick={this.setOrderOutletDateTimeData.bind(this)} className="button" value="Continue" />
					</div>
					
				</div>
				</div>
			</div>
			</div>
			{/* success popup - end */}

	
				{/* Delevery Popup error - start */}
			  <div id="error-postal-popup" className="white-popup mfp-hide popup_sec error_postal_popup">
					<div className="popup_equalrw">
					  <div className="popup_ttsec">
						<div className="innervmid_in">
						  <div className="pop_title poptt_icontop text-center">
							<img src={iconUnhappy} />
							<h2 className="text-uppercase">Sorry !</h2>
							<small>We cant find your postal code</small>

						  </div>
						</div>
					  </div>
					  <div className="popup_right">
						<div className="innervmid_in">
						 {cookie.load("UserId") &&  <div className="address-list-cls address-list-error">
							{this.userAddressList(2)}
						    </div>}
						  <div className="error-title">Change Your Postal Code</div>
						  <form className="form_sec">
							<div className="form-group">
							  <div className={(this.state.secondaryaddresslist.length > 0)?"focus-out focused":"focus-out"}>
								<label>Enter your postal code</label>
								<input type="text" id="postalcode1" onKeyPress={(e) => this.validateIntval(e)} maxLength="6" className="form-control input-focus" />
								<div className="postal_error"></div>
							  </div>
							</div>
							<div className="btn_sec delivery_submit_cls delivery_submit_div">
							  <input type="button" onClick={this.findOutletBasedZone.bind(this, 0, deliveryId)} className="button delivery_submit" value="Continue" />
							</div>
						  </form>
						</div>
					  </div>
					</div>
				  </div>
				  {/* Delevery Popup error - Start */}

				

		   {/* Timeslot error popup - start */}
            <div id="outlet-error-popup" className="white-popup mfp-hide popup_sec warning_popup outlet_error_popup">
            <div className="custom_alert">
                <div className="custom_alertin">
                    <div className="alert_height">
                        <div className="alert_body">
                            <img className="warning-popup-img" src={warningImg} />
                            <h2 className="text-uppercase">Sorry</h2>
                            <p>{'We can`t Deliver At the Moment!'}</p>
                            <p>Please come back again.</p> 
                            <div className="alt_btns">
                            <a href="javascript:;" onClick={this.gobckOutletpopup.bind(this)}  className="button button-right popup-modal-dismiss">change address</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </div>
            {/* Timeslot error popup - end */}

            	 <Footer />
				
			    </div>
			   )
	  }	
}



const mapStateTopProps = (state) => {


	var zonedetailArr = Array();
	if(Object.keys(state.zonedetail).length > 0) {
		   if(state.zonedetail[0].status === 'ok') {
			   zonedetailArr = state.zonedetail[0].result_set;
		   }
	}


	var secondarydataArr = Array();
	if(Object.keys(state.secondaryaddress).length > 0) {
		   if(state.secondaryaddress[0].status === 'ok') {
			   secondarydataArr = state.secondaryaddress[0].result_set;
		   }
	}

	return {
	  menudata: state.menudata,
	  socialsettingData: state.socialsettings,
	  secondaryaddresslist: secondarydataArr,
	  zonedetails: zonedetailArr,
	}
  }

  const mapDispatchToProps = (dispatch) => {
	return {
	
	  getMenuData: (menuslug) => {
		dispatch({ type: GET_MENUDATA, menuslug });
	  },
	  getSecondaryAddress: () => {
		dispatch({ type: GET_ALLUSERSECADDRDATA });
	  },
	  getZoneDetail: (outletId, zoneId) => {
		dispatch({ type: GET_ZONE_DETAIL, outletId, zoneId });
	  },
	}
  }

export default connect(mapStateTopProps, mapDispatchToProps)(withRouter(Home));

