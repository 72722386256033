import React,{Component} from 'react';
import cookie from 'react-cookies';

class Logout extends Component {

    componentWillMount(){
		
		cookie.remove('Token');
		localStorage.removeItem('BeaerToken');
        cookie.remove("UserId");
        cookie.remove("UserFname");
        cookie.remove("UserLname");
        cookie.remove("UserMobile");
        cookie.remove("UserEmail");
        cookie.remove('orderPaymentMode');
        cookie.remove('orderOutletId');
        cookie.remove('orderTableNo');
        cookie.remove('product_remarks');
        cookie.remove('orderOutletName');
        cookie.remove('orderZoneId');
        cookie.remove('carttotalitems');
        cookie.remove('cartsubtotal');
        cookie.remove('cartid');
		
        /* Delivery avilablity */
        cookie.remove('DeliveryDate');
        cookie.remove('DeliveryTime');
        cookie.remove('unitNoOne');
        cookie.remove('unitNoTwo');

        cookie.remove('promotion_id');
        cookie.remove('promotion_applied');
        cookie.remove('promotion_code');
        cookie.remove('promotion_delivery_charge_applied');
        cookie.remove('promotion_amount');
        cookie.remove('promotion_category');
        cookie.remove('prmo_type');
        
        /*Remove voucher*/
        cookie.remove('voucher_applied');
        cookie.remove('voucher_code');
        cookie.remove('voucher_amount');
        cookie.remove('orderIdTxt');
        cookie.remove('paymentIdTxt');

        cookie.remove("orderIdTxt", { path: "/" });
        cookie.remove("paymentIdTxt", { path: "/" });

        cookie.remove("defaultAvilablityId", { path: "/" });
        cookie.remove("outletchosen", { path: "/" });


        this.props.history.push('/');



		
		   // setTimeout(() => this.props.history.push('/'), 2000)
		
		

  }

  render() {
    return (
      <h1 className="loading-text">
        Logging out...
      </h1>
    );
  }
}

export default Logout;
